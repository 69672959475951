import { apiGet } from '@/common/axios';
import { ListResponse } from '@/common/interfaces';
import { SimpleTrainingRequest, TrainingRequestDetail } from '@/common/api/digitalOffice';
import { TrainingType } from '@/common/enums';

export interface ClientTrainingRequestFilters {
  training_stage?: 'ongoing' | undefined;
  training_type?: TrainingType;
  status?: string;
  start_date?: string;
  end_date?: string;
  page: number;
  page_size?: number;
  search?: string;
}

export const getUserTrainingRequests = async (params: ClientTrainingRequestFilters) => {
  const { data } = await apiGet<ListResponse<SimpleTrainingRequest>>(
    '/accounts/me/training-requests/',
    params,
  );
  return data;
};

export const getUserTrainingRequestDetail = async (requestId: number | string) => {
  const { data } = await apiGet<TrainingRequestDetail>(
    `/accounts/me/training-requests/${requestId}/`,
  );
  return data;
};
