import dayjs from 'dayjs';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Button, InputDate, InputDropdown, InputDropdownItem, InputText } from '@unbooking/ui-kit';
import {
  DOTrainingRequestFilters,
  getDOStatuses,
  getDOTrainingRequests,
  SimpleTrainingRequest,
} from '@/common/api/digitalOffice';
import { FC, useState } from 'react';
import TrainingRequestTable from './TrainingRequestTable';
import { TrainingProgramSelect } from '../../inputs/TrainingProgramSelect';
import { useDebounce } from 'use-debounce';
import { Spinner } from '@components';

const DEBOUNCE_MS = 400;

export const TrainingRequestList: FC = () => {
  const [trainingRequests, setTrainingRequests] = useState<SimpleTrainingRequest[]>([]);
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [filters, setFilters] = useState<DOTrainingRequestFilters>({});
  const [resultsCount, setResultsCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [rowLimit, setRowLimit] = useState<number>(10);
  // Force rerender of UI Kit components that doesn't follow value change
  const [rerenderKey, setRerenderKey] = useState(0);

  // Use debounce to search only when user stops typing
  const [debouncedSearch] = useDebounce(searchPhrase, DEBOUNCE_MS);

  const { isFetching } = useQuery({
    queryKey: ['training-request-list', page, rowLimit, filters, debouncedSearch],
    queryFn: async () => {
      const res = await getDOTrainingRequests({
        page,
        page_size: rowLimit,
        search: debouncedSearch ?? undefined,
        ...filters,
      });
      setResultsCount(res.count);
      setTrainingRequests(res.results);
      return res;
    },
    staleTime: DEBOUNCE_MS,
    placeholderData: keepPreviousData,
  });
  const { isLoading: statusLoading, data: statuses = [] } = useQuery({
    queryKey: ['do-statuses'],
    queryFn: getDOStatuses,
  });

  const onDatesSelect = (dateRange: string) => {
    const [dateFrom, dateTo] = dateRange.split(' - ');
    // start_date <= dateTo
    updateFilters('start_date', dayjs(dateTo).format('YYYY-MM-DD'));
    // end_date >= dateFrom
    updateFilters('end_date', dayjs(dateFrom).format('YYYY-MM-DD'));
  };

  const clearFilters = () => {
    setSearchPhrase('');
    setFilters({} as DOTrainingRequestFilters);
    setRerenderKey(key => key + 1);
  };

  const updateFilters = (key: keyof DOTrainingRequestFilters, value: string) => {
    setFilters(f => ({
      ...f,
      [key]: value,
    }));
  };

  const isLoading = isFetching || statusLoading;
  return (
    <div className="w-full">
      {isLoading && <Spinner fullScreen />}
      <div className="flex flex-col max-h-full bg-catskill-white m-5 p-5 overflow-y-auto gap-3 h-[calc(100vh-240px)]">
        <div className="flex flex-row items-center justify-between">
          <div className="text-2xl font-light">{resultsCount} training requests</div>
        </div>
        <div className="flex flex-row items-end justify-start gap-8 mb-3">
          <div className="w-72 self-end">
            <InputText
              name="search"
              placeholder="Search requests..."
              value={searchPhrase}
              onChange={e => setSearchPhrase(e.target.value)}
              light
              fullWidth
            />
          </div>
          <div className="w-72">
            <div className="mb-2">Training program</div>
            <TrainingProgramSelect
              selectedProgram={filters.training_type}
              onProgramSelect={trainingType => updateFilters('training_type', trainingType)}
              showLabel={false}
              light
              placeholder="All"
            />
          </div>
          <div className="w-72">
            <div className="mb-2">Proposed event date</div>
            <InputDate
              key={rerenderKey}
              name="training-request-dates"
              onChange={e => onDatesSelect(e.target.value)}
              mode="range"
              placeholder="All"
              light
              fullWidth
            />
          </div>
          <div className="w-72">
            <div className="mb-2">Status</div>
            <InputDropdown
              value={filters.status}
              onChange={event => updateFilters('status', event.target.value)}
              items={statuses as unknown as InputDropdownItem[]}
              name="status-select"
              placeholder="All"
              light
              fullWidth
            />
          </div>
          <div className="self-end">
            <Button label="Clear all" plain color="primary" onClick={clearFilters} />
          </div>
        </div>
        <TrainingRequestTable
          trainingRequests={trainingRequests}
          statuses={statuses}
          page={page}
          setPage={setPage}
          rowLimit={rowLimit}
          setRowLimit={setRowLimit}
          resultsCount={resultsCount}
        />
      </div>
    </div>
  );
};

export default TrainingRequestList;
