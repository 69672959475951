import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from '@/common/path';
import { AttendeesIcon, CalendarOptionIcon, LocationIcon, ArrowIcon } from '@icons';
import { pluralize } from '@/common/utils';
import { IStatus, TrainingRequestDetail } from '@/common/api/digitalOffice';
import { getAgencies, getEventDate } from '@/components/digital-office/utils';
import { StatusBlock } from '@components';

interface IMainInfo {
  trainingRequest: TrainingRequestDetail;
}

const MainInfo: FC<IMainInfo> = ({ trainingRequest }) => {
  const agencies = useMemo(() => getAgencies(trainingRequest), [trainingRequest]);
  const eventDate = useMemo(() => getEventDate(trainingRequest), [trainingRequest]);

  const agencyLabel = pluralize(trainingRequest?.agencies.length, 'agency', 'agencies');
  const statusToOption = {
    [trainingRequest.status]: trainingRequest.status_option ?? ({} as IStatus),
  };

  return (
    <section className="bg-catskill-white m-5 p-5 pb-12">
      <Link
        className="flex items-center gap-2 font-medium mb-3 w-fit p-4 -ml-4 -mt-4 text-sky-500 hover:text-sky-600"
        to={PATHS.userTrainingRequests}
      >
        <ArrowIcon direction="left" className="inline-block" /> All requests list
      </Link>
      <div className="text-sm">Request ID: {trainingRequest.request_id}</div>
      <h1 className="text-3xl mb-6">{trainingRequest.program}</h1>
      <div className="mb-8">
        <span className="text-oslo-gray">{`Training requested for ${agencyLabel}:`}</span>{' '}
        {agencies}
      </div>
      <div className="flex flex-wrap whitespace-nowrap items-center gap-8">
        <div className="flex gap-2 items-center">
          <LocationIcon stroke="black" /> {trainingRequest.country_location_name}
        </div>
        <div className="flex gap-2 items-center">
          <CalendarOptionIcon />
          {eventDate}
        </div>
        <div className="flex gap-2 items-center">
          <AttendeesIcon />
          {trainingRequest.num_of_attendees} participant
          {pluralize(Number(trainingRequest.num_of_attendees))}
        </div>
        <div className="flex-1" />
        <div className="flex basis-[450px] gap-6 items-center pr-10">
          <label className="font-bold">Request status</label>
          <StatusBlock status={trainingRequest.status} statusToOption={statusToOption} />
        </div>
      </div>
    </section>
  );
};

export default MainInfo;
