export const PATHS = {
  home: '/',
  impact: '/impact',
  trainings: '/trainings',
  instructors: '/instructors',
  contact: '/contact',
  trainingDetails: '/training-programs/:id',
  requestTraining: '/request_training',
  requestTrainingSuccess: '/request_training/success/:requestId',
  whoAreWe: '/who-are-we',
  login: '/login',

  // Digital Office
  digitalOffice: '/digital-office',
  digitalOfficeTrainingDetails: '/digital-office/training-request/:trainingRequestId',
  digitalOfficeTrainings: '/digital-office/training-requests',
  digitalOfficePlanner: '/digital-office/planner',
  digitalOfficeAnalytics: '/digital-office/analytics',

  // User menu
  userProfile: '/profile',
  userTrainingRequests: '/profile/training-requests',
  userOngoingTrainingRequests: '/profile/training-requests?type=ongoing',
  userTrainingDetails: '/profile/training-requests/:trainingRequestId',
};
