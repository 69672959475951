import { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { Spinner } from '@components';
import { getUserTrainingRequestDetail } from '@/common/api/client';
import { UserProfileBg } from '@/assets/img';
import { MainInfo, RequestDetails } from './components';

export const UserProfileRequestDetail: FC = () => {
  const { trainingRequestId } = useParams() as { trainingRequestId: string };

  const { isFetching, data } = useQuery({
    queryKey: ['user-profile-training-request-detail', trainingRequestId],
    queryFn: () => getUserTrainingRequestDetail(trainingRequestId),
  });

  return (
    <div
      style={{ backgroundImage: `url(${UserProfileBg})` }}
      className="flex flex-1 bg-no-repeat bg-cover bg-center"
    >
      <div className="w-full bg-gradient-to-r from-black to-transparent">
        {isFetching && <Spinner fullScreen />}
        {data ? (
          <>
            <MainInfo trainingRequest={data} />
            {/*Separator*/}
            <div className="pb-5" />
            <RequestDetails trainingRequest={data} />
            <div className="pb-5" />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default UserProfileRequestDetail;
