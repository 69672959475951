import { FC, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  Button,
  InputDate,
  InputDropdown,
  InputDropdownItem,
  InputText,
  Tabs,
} from '@unbooking/ui-kit';
import { Spinner } from '@components';
import { DOTrainingRequestFilters, getDOStatuses } from '@/common/api/digitalOffice';
import { TrainingProgramSelect } from '@/components/inputs/TrainingProgramSelect';
import { getUserTrainingRequests } from '@/common/api/client';
import UserRequestTable from './UserRequestTable';
import { UserProfileBg } from '@/assets/img';

const DEBOUNCE_MS = 400;

export const UserProfileRequestList: FC = () => {
  const params = new URLSearchParams(window.location.search);
  const initialTrainingType = params.get('type') === 'ongoing' ? 'ongoing' : 'all';

  const [activeTab, setActiveTab] = useState<string>(initialTrainingType);
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [filters, setFilters] = useState<DOTrainingRequestFilters>({});
  const [page, setPage] = useState<number>(1);
  const [rowLimit, setRowLimit] = useState<number>(10);
  // Force rerender of UI Kit components that doesn't follow value change
  const [rerenderKey, setRerenderKey] = useState(0);

  // Use debounce to search only when user stops typing
  const [debouncedSearch] = useDebounce(searchPhrase, DEBOUNCE_MS);

  const {
    isFetching,
    data: { count: resultsCount, results: trainingRequests } = { count: 0, results: [] },
  } = useQuery({
    queryKey: ['user-training-request-list', activeTab, page, rowLimit, filters, debouncedSearch],
    queryFn: async () => {
      return await getUserTrainingRequests({
        page,
        page_size: rowLimit,
        search: debouncedSearch ?? undefined,
        training_stage: activeTab === 'ongoing' ? 'ongoing' : undefined,
        ...filters,
      });
    },
    staleTime: DEBOUNCE_MS,
    placeholderData: keepPreviousData,
  });
  const { isLoading: statusLoading, data: statuses = [] } = useQuery({
    queryKey: ['do-statuses'],
    queryFn: async () => {
      const statuses = await getDOStatuses();
      return statuses.map(status => ({ ...status, label: status.user_label }));
    },
  });

  const userStatuses = useMemo(() => {
    const usedLabels = new Set();
    return statuses.filter(status => {
      if (usedLabels.has(status.user_label)) return false;
      usedLabels.add(status.user_label);
      return true;
    });
  }, [statuses]);

  const onDatesSelect = (dateRange: string) => {
    const [dateFrom, dateTo] = dateRange.split(' - ');
    // start_date <= dateTo
    updateFilters('start_date', dayjs(dateTo).format('YYYY-MM-DD'));
    // end_date >= dateFrom
    updateFilters('end_date', dayjs(dateFrom).format('YYYY-MM-DD'));
  };

  const clearFilters = () => {
    setSearchPhrase('');
    setFilters({} as DOTrainingRequestFilters);
    setRerenderKey(key => key + 1);
  };

  const updateFilters = (key: keyof DOTrainingRequestFilters, value: string) => {
    setFilters(f => ({
      ...f,
      [key]: value,
    }));
  };

  const isLoading = isFetching || statusLoading;
  return (
    <div
      style={{ backgroundImage: `url(${UserProfileBg})` }}
      className="flex flex-1 bg-no-repeat bg-cover bg-center"
    >
      <div className="w-full bg-gradient-to-r from-black to-transparent">
        {isLoading && <Spinner fullScreen />}
        <div className="flex flex-col max-h-full bg-[#ebebeb] m-5 p-5 overflow-y-auto gap-3 h-[calc(100vh-240px)]">
          <div className="flex flex-row items-center justify-between">
            <div className="text-2xl font-light">{resultsCount} training requests</div>
          </div>
          <div className="flex flex-row items-end justify-start gap-8 mb-3">
            <div className="w-72 self-end">
              <InputText
                name="search"
                placeholder="Search requests..."
                value={searchPhrase}
                onChange={e => setSearchPhrase(e.target.value)}
                light
                fullWidth
              />
            </div>
            <div className="w-72">
              <div className="mb-2">Training program</div>
              <TrainingProgramSelect
                selectedProgram={filters.training_type}
                onProgramSelect={trainingType => updateFilters('training_type', trainingType)}
                showLabel={false}
                light
                placeholder="All"
              />
            </div>
            <div className="w-72">
              <div className="mb-2">Proposed event date</div>
              <InputDate
                key={rerenderKey}
                name="training-request-dates"
                onChange={e => onDatesSelect(e.target.value)}
                mode="range"
                placeholder="All"
                light
                fullWidth
              />
            </div>
            <div className="w-72">
              <div className="mb-2">Status</div>
              <InputDropdown
                value={filters.status}
                onChange={event => updateFilters('status', event.target.value)}
                items={userStatuses as unknown as InputDropdownItem[]}
                name="status-select"
                placeholder="All"
                light
                fullWidth
              />
            </div>
            <div className="self-end">
              <Button label="Clear all" plain color="primary" onClick={clearFilters} />
            </div>
          </div>
          <Tabs
            light
            tabs={[
              {
                id: 'all',
                title: 'All',
                Component: (
                  <UserRequestTable
                    trainingRequests={trainingRequests}
                    statuses={statuses}
                    page={page}
                    setPage={setPage}
                    rowLimit={rowLimit}
                    setRowLimit={setRowLimit}
                    resultsCount={resultsCount}
                  />
                ),
              },
              {
                id: 'ongoing',
                title: 'Ongoing',
                Component: (
                  <UserRequestTable
                    trainingRequests={trainingRequests}
                    statuses={statuses}
                    page={page}
                    setPage={setPage}
                    rowLimit={rowLimit}
                    setRowLimit={setRowLimit}
                    resultsCount={resultsCount}
                  />
                ),
              },
            ]}
            active={activeTab}
            setActive={setActiveTab}
          />
        </div>
      </div>
    </div>
  );
};

export default UserProfileRequestList;
