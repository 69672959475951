import React, { FC } from 'react';
import { IStatus } from '@/common/api/digitalOffice';

interface IStatusBlock {
  status: string;
  statusToOption: Record<string, IStatus>;
}

export const StatusBlock: FC<IStatusBlock> = ({ status, statusToOption }) => {
  const { label, color, background_color: backgroundColor } = statusToOption[status] ?? {};
  return (
    <div className="rounded-lg font-bold py-1 px-2" style={{ color, backgroundColor }}>
      <span className="scale-50 mr-2 inline-block">⬤</span>
      {label || '-'}
    </div>
  );
};

export default StatusBlock;
